import React from "react";

export default function Footer() {
  return (
    <div className="bg-darkBlue w-full flex gap-6 items-center justify-center py-4 md:px-8 px-4">
      <p className="text-white md:text-sm text-xs text-center w-full">
        © 2024 Dignity Alliance International. All Rights Reserved
      </p>
    </div>
  );
}
