import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Logo from "@/components/ui/logo";
import Link from "next/link";
import { useSingleModal } from "@/contexts";
import { siteSettings } from "@/data/site-settings";
import Hamburger from "./hamburger";
import HeaderMenu from "./header-menu";
import cn from "classnames";
import { m } from "framer-motion";

export default function Header() {
  const router = useRouter();
  const { showNavMenu, setShowNavMenu } = useSingleModal();

  useEffect(() => {
    setShowNavMenu(false);
  }, [router?.pathname]);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header
        className={cn(
          "w-full flex-col md:h-[100px] h-[80px] bg-body fixed transition-all duration-300 ease z-[9999] inset-0 bg-white bg-clip-padding backdrop-filter backdrop-blur-[15px] bg-opacity-30"
        )}
      >
        <m.div
          initial={{ y: -70, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 100,
            transition: { duration: 0.5, delay: 0.1 },
          }}
          viewport={{ once: true }}
          className="flex w-full h-full items-center justify-between relative my-auto lg:px-16 md:px-12 px-4"
        >
          <div className="flex items-center justify-between w-full">
            <Logo />
            <HeaderMenu data={siteSettings?.site_header?.menu} />
            <Hamburger />
          </div>
        </m.div>

        <div
          className={`${
            showNavMenu ? "h-[300px]" : "h-0"
          } overflow-hidden fixed md:top-[84px] top-[80px] transition-all duration-300 ease right-0 min-w-full flex flex-col px-4 justify-evenly items-center bg-[#DAEEFB]`}
        >
          {siteSettings?.site_header?.menu?.map((row, index) => (
            <div
              className={`${
                showNavMenu ? "opacity-100" : "opacity-0"
              } w-full py-4 border-b-[1px] last:border-b-0 border-darkGrey border-dashed transition-all duration-300 ease`}
              key={"navLink" + index}
            >
              <Link
                href={row.path}
                onClick={() => setShowNavMenu(false)}
                className="w-full text-xl text-darkGrey text-center"
              >
                {row.label}
              </Link>
            </div>
          ))}
        </div>
      </header>
    </>
  );
}
