import "../styles/globals.css";
import Layout from "@/components/layout";
import { SingleModalProvider } from "@/contexts";
import { AnimatePresence, m } from "framer-motion";
import { LazyMotion, domAnimation } from "framer-motion";
import { DefaultSeo } from "next-seo";

function MyApp({ Component, pageProps, router }) {
  return (
    <AnimatePresence mode="wait">
      <LazyMotion features={domAnimation}>
        <SingleModalProvider>
          <DefaultSeo
            title="Dignity Alliance International"
            defaultTitle="Dignity Alliance International"
            keywords="Dignity Alliance International , United Kingdom, non profit"
            description="Dignity Alliance International (DAI) is a UK based charity committed to ending violence against women and children, and all forms of modern slavery across South Asia. (DAI) is a UK based charity committed to ending violence against women and children, and all forms of modern slavery across South Asia."
            canonical="https://dignityallianceinternational.org/"
            openGraph={{
              url: "https://dignityallianceinternational.org/",
              title: "Dignity Alliance International",
              description:
                "Dignity Alliance International (DAI) is a UK based charity committed to ending violence against women and children, and all forms of modern slavery across South Asia. (DAI) is a UK based charity committed to ending violence against women and children, and all forms of modern slavery across South Asia.",
              images: [
                {
                  url: "https://dignityallianceinternational.org/images/DAI-logo.webp",
                  width: 140,
                  height: 140,
                  alt: "Dignity Alliance International",
                },
              ],
            }}
            twitter={{
              // handle: "@avneesh0612",
              // site: "@avneesh0612",
              cardType: "summary_large_image",
            }}
            additionalLinkTags={[
              {
                rel: "icon",
                href: `/images/favicon.png`,
              },
            ]}
          />
          <Layout>
            <m.div
              key={router.route}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.35 } }}
              exit={{ opacity: 0 }}
              className="h-auto w-full"
            >
              <Component {...pageProps} key={router.route} />
            </m.div>
          </Layout>
        </SingleModalProvider>
      </LazyMotion>
    </AnimatePresence>
  );
}

export default MyApp;
