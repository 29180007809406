import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import { useRouter } from "next/router";
import { Sora } from "next/font/google";

const poppins = Sora({
  weight: ["200", "300", "400", "500", "600", "700", "800"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-poppins",
});

const Layout = ({ children }) => {
  const router = useRouter();
  return (
    <div
      className={`w-full h-auto block relative min-h-screen ${poppins.variable} font-poppins`}
    >
      <Header />
      <main className={``}>{children}</main>
      <Footer />
    </div>
  );
};
export default Layout;
