export const siteSettings = {
  site_header: {
    menu: [
      {
        id: 1,
        label: "What we do",
        path: "/#what-we-do",
      },
      {
        id: 2,
        label: "How we do this",
        path: "/#how-we-do-this",
      },
      {
        id: 3,
        label: "Governance",
        path: "/#governance",
      },
      {
        id: 4,
        label: "Get in touch",
        path: "/#get-in-touch",
      },
    ],
  },
};
