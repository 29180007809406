import React from "react";
import { useSingleModal } from "@/contexts";

export default function Hamburger({ color = "bg-[#eaeaea]" }) {
  const { toggleNavMenu, showNavMenu } = useSingleModal();
  const genericHamburgerLine = `h-[2px] shrink-0 bg-blue rounded-xl transition ease transform duration-300`;
  return (
    <button
      className={`md:top-8 top-4 lg:right-16 md:right-12 right-4 rounded absolute z-[100] w-[25px] h-[45px] lg:hidden flex flex-col justify-center gap-[6px] items-center group outline-none overflow-hidden focus:outline-none transition-all duration-200 ease`}
      onClick={toggleNavMenu}
    >
      <div
        className={`${genericHamburgerLine} w-full ${
          showNavMenu ? "rotate-45 translate-y-[8px] translate-x-0" : "rotate-0"
        }`}
      />
      <div
        className={`${genericHamburgerLine} w-[50%] mx-auto ${
          showNavMenu ? "opacity-0 -translate-x-2" : "opacity-100 translate-x-0"
        } `}
      />
      <div
        className={`${genericHamburgerLine} ${
          showNavMenu
            ? "-rotate-45 -translate-y-[8px] -translate-x-0 opacity-100"
            : "rotate-0"
        } w-full`}
      />
    </button>
  );
}
