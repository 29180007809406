import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSingleModal } from "@/contexts";
import cn from "classnames";
export default function HeaderMenu({ data }) {
  const router = useRouter();
  const linkClass =
    "hover:text-blue font-body inline-flex transition-all duration-200 ease items-center lg:text-base text-sm py-2 relative focus:outline-none outline-none";

  return (
    <nav
      className={`headerMenu w-auto relative items-center md:gap-[38px] hidden lg:flex shrink-0 `}
    >
      {data?.map((item) => (
        <div className={`menuItem group h-fit cursor-pointer`} key={item.id}>
          {item?.path ? (
            <Link
              href={item.path}
              // scroll={false}
              className={cn(
                router.pathname === item.path && "font-bold text-darkBlue",
                router.pathname !== item.path && "font-normal text-darkBlue",
                linkClass
              )}
            >
              {item.label}
            </Link>
          ) : (
            <div
              className={cn(
                router.pathname === item.path && "font-bold text-darkBlue",
                router.pathname !== item.path && "font-medium text-darkBlue",
                linkClass
              )}
            >
              {item.label}
            </div>
          )}
        </div>
      ))}
    </nav>
  );
}
