import React from "react";
import Link from "next/link";
import { useSingleModal } from "@/contexts";
import Image from "next/image";
export default function Logo({
  size = "lg:w-[145px] lg:h-[57px] w-[120px] h-[37px] ",
}) {
  const { setShowNavMenu, showNavMenu, setShowModal } = useSingleModal();
  return (
    <Link
      href="#"
      className={`${size} relative flex shrink-0 justify-start items-start object-contain text-left z-[100]`}
    >
      <Image
        onClick={() => setShowNavMenu(false)}
        src="/images/DAI-logo.webp"
        alt="DAI logo"
        sizes="(max-width: 300px) 50px, 100px"
        fill
        className="w-full h-full object-contain object-left"
      />
    </Link>
  );
}
